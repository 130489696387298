<template>
    <LocationForm :id="id" ref="form" @save="save" />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { httpHelper } from '@/utils'
import LocationForm from '../LocationForm'

export default {
    name: 'EditLocationView',
    components: {
        LocationForm,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            initialValues: {},
            saved: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['isAdminOrStaff']),
        changed() {
            return this.locationChanged || this.permissionsChanged
        },
        locationChanged() {
            const {
                name,
                description,
                geofence_tolerance,
                geofence,
            } = this.$refs.form.getValues()

            return (
                this.initialValues.name !== name ||
                this.initialValues.description !== description ||
                this.initialValues.geofence_tolerance !== geofence_tolerance ||
                !geofence ||
                this.initialValues.geofence.coordinates[0].length !==
                    geofence.coordinates[0].length ||
                // use tolerance of 1e-6 in the comparison of coordinates to avoid
                // false detection of changes
                this.initialValues.geofence.coordinates[0]
                    .map((k, i) => [
                        [k[0], geofence.coordinates[0][i][0]],
                        [k[1], geofence.coordinates[0][i][1]],
                    ])
                    .flat()
                    .some(p => Math.abs(p[0] - p[1]) > 1e-6)
            )
        },
        permissionsChanged() {
            const {
                usersViewingAccess,
                usersEditingAccess,
            } = this.$refs.form.getPermissions()

            return (
                this.initialValues.usersViewingAccess !== usersViewingAccess ||
                this.initialValues.usersEditingAccess !== usersEditingAccess
            )
        },
    },
    async mounted() {
        this.$refs.form.setLoading(true)
        const { data } = await httpHelper.get(`/locations/${this.id}/`)
        if (this.isAdminOrStaff) {
            const allPermissions = await httpHelper.get(
                `/location-permissions/`
            )
            const permissionData = allPermissions.data.results.filter(
                item => item.location == this.id
            )
            this.initialValues.usersViewingAccess = permissionData
                .filter(item => item.access === 'READ')
                .map(item => item.user)
            this.initialValues.usersEditingAccess = permissionData
                .filter(item => item.access === 'WRITE')
                .map(item => item.user)
        }
        this.initialValues.name = data.name
        this.initialValues.owner = data.owner
        this.initialValues.description = data.description
        this.initialValues.geofence = data.geofence
        this.initialValues.geofence_tolerance = data.geofence_tolerance
        this.$refs.form.setValues(this.initialValues)
        this.setActiveLocationOnMap(data)
        this.$refs.form.setLoading(false)
    },
    destroyed() {
        this.setActiveLocationOnMap(null)
    },
    methods: {
        ...mapMutations('location', [
            'setActiveLocationOnMap',
            'updateLocation',
        ]),
        async save(values) {
            if (!this.changed) {
                this.saved = true
                this.$refs.form.saved(this.id)
                return
            }
            const { data } = await httpHelper.put(`/locations/${this.id}/`, {
                ...values,
                owner: this.initialValues.owner,
            })
            this.updateLocation(data)
            this.saved = true
            this.$refs.form.saved(data.id)
            if (this.isAdminOrStaff) {
                this.manageAccess()
            }
        },
        async manageAccess() {
            const {
                usersViewingAccess,
                usersEditingAccess,
            } = this.$refs.form.getPermissions()

            const { data } = await httpHelper.get('/location-permissions/')
            const permissionData = data.results.filter(
                item => item.location == this.id
            )

            const usersViewingAccessExisting = permissionData
                .filter(item => item.access === 'READ')
                .map(item => item.user)

            const usersEditingAccessExisting = permissionData
                .filter(item => item.access === 'WRITE')
                .map(item => item.user)

            permissionData.forEach(item => {
                if (
                    item.access === 'READ' &&
                    usersEditingAccess.includes(item.user)
                ) {
                    usersEditingAccessExisting.push(item.user)
                    return httpHelper.patch(
                        `/location-permissions/${item.id}/`,
                        { access: 'WRITE' }
                    )
                }

                if (
                    item.access === 'WRITE' &&
                    usersViewingAccess.includes(item.user)
                ) {
                    usersViewingAccessExisting.push(item.user)
                    return httpHelper.patch(
                        `/location-permissions/${item.id}/`,
                        { access: 'READ' }
                    )
                }

                if (
                    (item.access === 'READ' &&
                        !usersViewingAccess.includes(item.user)) ||
                    (item.access === 'WRITE' &&
                        !usersEditingAccess.includes(item.user))
                ) {
                    return httpHelper.drop(`/location-permissions/${item.id}/`)
                }
            })

            usersViewingAccess.forEach(user => {
                if (!usersViewingAccessExisting.includes(user)) {
                    httpHelper.post('/location-permissions/', {
                        location: this.id,
                        user,
                        access: 'READ',
                    })
                }
            })

            usersEditingAccess.forEach(user => {
                if (!usersEditingAccessExisting.includes(user)) {
                    httpHelper.post('/location-permissions/', {
                        location: this.id,
                        user,
                        access: 'WRITE',
                    })
                }
            })
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.changed || this.saved) {
            next()
            return
        }

        this.$refs.form.confirmLeave(this.$t('confirmLeave'), confirmed => {
            next(confirmed)
        })
    },
}
</script>

<i18n>
{
    "en": {
        "confirmLeave": "Really discard changes?"
    },
    "de": {
        "confirmLeave": "Änderungen wirklich verwerfen?"
    },
    "fr": {
        "confirmLeave": "Souhaitez-vous réellement abandonner les modifications ?"
    }
}
</i18n>
